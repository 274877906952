import store from "../../store";

import axios from "axios";
// import * as ML from './medialibrary';

export const loginActual = (data) => {
  return {
    type: "LOGIN",
    data,
    meta: {
      remote: true,
      client: "webapp",
      version: "0.7.0",
      theme: process.env.REACT_APP_THEME ? process.env.REACT_APP_THEME : "default",
    },
  };
};

export const login = (data) => {
  return (dispatch) => {
    dispatch(loginActual(data));
    //dispatch(generateLoginToken(data));
  };
};

export const setTheme = (theme) => {
  return {
    type: "APP_THEME",
    theme,
  };
};

export const loginWithTokenActual = (token) => {
  // console.log("generateLoginToken: " + JSON.stringify(user));

  let data = {
    loginToken: token,
  };
  return {
    type: "LOGIN_WITH_TOKEN",
    data,
    meta: { remote: true },
  };
};

export const loginWithToken = (token) => {
  // alert("Attempting login with token")
  return (dispatch) => {
    dispatch(loginWithTokenActual(token));
  };
};

export const loginRedirectActual = () => {
  return {
    type: "LOGIN_REDIRECTED",
  };
};
export const loginRedirect = () => {
  return (dispatch) => {
    dispatch(loginRedirectActual());
  };
};

export const newVideo = (data) => {
  console.log("json: " + JSON.stringify(data));
  return {
    type: "CREATE_NEW_VIDEO",
    data,
    meta: { remote: true },
  };
};

export const newVideoTest = (data) => {
  console.log("json: " + JSON.stringify(data));
  return {
    type: "CREATE_VIDEO_TEST",
    data,
    meta: { remote: true },
  };
};

export const renderScenePreview = (data) => {
  console.log("json: " + JSON.stringify(data));
  return {
    type: "RENDER_SCENE_PREVIEW",
    data,
    meta: { remote: true },
  };
};

export const DeleteScene = (data) => {
  console.log("json: " + JSON.stringify(data));
  return {
    type: "DELETE_SCENE",
    data,
    meta: { remote: true },
  };
};

export const DeletePlaceholder = (data) => {
  console.log("json: " + JSON.stringify(data));
  return {
    type: "DELETE_PLACEHOLDER",
    data,
    meta: { remote: true },
  };
};

export const addPlaceholder = (data) => {
  return {
    type: "ADD_PLACEHOLDER",
    data,
    meta: { remote: true },
  };
};

export const importData = (data) => {
  //console.log("json: " + JSON.stringify(data));
  return {
    type: "IMPORT_DATA",
    data,
    meta: { remote: true },
  };
};

export const AnalyzeVideo = (data) => {
  //console.log("form: " + JSON.stringify(data));
  return {
    type: "ANALYZE_VIDEO",
    data,
    meta: { remote: true },
  };
};

export const editPlaceholder = (data) => {
  //console.log("form: " + JSON.stringify(data));
  return {
    type: "EDIT_PLACEHOLDER",
    data,
    meta: { remote: true },
  };
};

export const editScene = (data) => {
  //console.log("form: " + JSON.stringify(data));
  return {
    type: "EDIT_SCENE",
    data,
    meta: { remote: true },
  };
};

export const modifySceneTiming = (data) => {
  //console.log("form: " + JSON.stringify(data));
  return {
    type: "MODIFY_SCENE_TIMING",
    data,
    meta: { remote: true },
  };
};

export const messageShown = () => {
  console.log("message shown ");
  return {
    type: "MESSAGE_SHOWN",
  };
};

export const uploadFileFailed = (data) => {
  //console.log(JSON.stringify(data));
  return {
    type: "UPLOAD_FILE_FAILED",
    data,
  };
};

export const uploadFileItem = (data) => {
  //console.log(JSON.stringify(data));
  return {
    type: "UPLOAD_FILE_ITEM",
    data,
  };
};

export const edit_scene_order = (projectid, sceneid, oldSceneOrder, newSceneOrder) => {
  var data = {
    projectid: projectid,
    sceneid: sceneid,
    old_sceneorder: oldSceneOrder,
    new_sceneorder: newSceneOrder,
  };
  return {
    type: "EDIT_SCENE_ORDER",
    data,
    meta: {
      remote: true,
    },
  };
};
