import store from "../store";
import * as digitaika from "./digitaika";
import axios from "axios";
var config = require("../config");

// export digitaika;
//export const uploadFile = digitaika.uploadFile;
export const uploadFileItem = digitaika.uploadFileItem;

export const createVideoProjectFromPptx = (data) => {
  return {
    type: "CREATE_VIDEO_PROJECT_FROM_PPTX",
    data: data,
    meta: { remote: true },
  };
};

export const updatePreview = (data) => {
  return {
    type: "UPDATE_PREVIEW",
    data: data,
    meta: { remote: true },
  };
};

export const confirmImportSettings = (data) => {
  return {
    type: "CONFIRM_IMPORT_SETTINGS",
    data: {
      ...data,
    },
    meta: { remote: true },
  };
};

export const orchestratorRaiseEvent = (data) => {
  return {
    type: "ORCHESTRATOR_RAISE_EVENT",
    data: data,
    meta: { remote: true },
  };
};

export const createImportEntity = (data) => {
  return {
    type: "CREATE_IMPORT_ENTITY",
    data: {
      ...data,
    },
    meta: { remote: true },
  };
};

export const updateImportEntityGlobalSettings = (data) => {
  return {
    type: "UPDATE_IMPORT_ENTITY_GLOBALSETTINGS",
    data: {
      ...data,
    },
    meta: { remote: true },
  };
};

export const setPauseAfter = (pauseAfter, projectid, sceneid) => {
  return {
    type: "SET_SCENE_PAUSEAFTER",
    data: {
      pauseAfter: pauseAfter,
      projectid: projectid,
      sceneid: sceneid,
    },
    meta: { remote: true },
  };
};

export const setGotoSlide = (gotoSlide, projectid, sceneid) => {
  return {
    type: "SET_SCENE_GOTOSLIDE",
    data: {
      gotoSlide: gotoSlide,
      projectid: projectid,
      sceneid: sceneid,
    },
    meta: { remote: true },
  };
};
export const importClienteventUpdate = (data) => {
  return {
    type: "IMPORT_CLIENTEVENT_UPDATE",
    data: {
      ...data,
    },
  };
};

export const updateImportEntityClientevents = (data) => {
  return {
    type: "UPDATE_IMPORT_ENTITY_CLIENTEVENTS",
    data: {
      ...data,
    },
    meta: { remote: true },
  };
};

export const clear_global_alert_error = () => {
  return {
    type: "CLEAR_GLOBAL_ALERT_ERROR",
  };
};

export const updatePlaceholderOrder = (data) => {
  return {
    type: "EDIT_PLACEHOLDER",

    data,
    meta: { remote: true },
  };
};

export const setProjectPublic = (projectId) => {
  return {
    type: "SET_PROJECT_PUBLIC",
    data: { projectid: projectId },
    meta: { remote: true },
  };
};

export const setShowNotes = (projectId) => {
  return {
    type: "SET_SHOW_NOTES",
    data: { projectid: projectId },
    meta: { remote: true },
  };
};

export const setShowPlayerDownload = (projectId) => {
  return {
    type: "SET_SHOW_PLAYERDOWNLOAD",
    data: { projectid: projectId },
    meta: { remote: true },
  };
};

export const setShowSourceDownload = (projectId) => {
  return {
    type: "SET_SHOW_SOURCEDOWNLOAD",
    data: { projectid: projectId },
    meta: { remote: true },
  };
};

export const setHideNotes = (projectId) => {
  return {
    type: "SET_HIDE_NOTES",
    data: { projectid: projectId },
    meta: { remote: true },
  };
};

export const setReplaceAvatar = (projectId, faceSwap) => {
  console.log("sending", { projectid: projectId, faceswap: faceSwap });
  return {
    type: "SET_REPLACE_AVATAR",
    data: { projectid: projectId, faceswap: faceSwap },
    meta: { remote: true },
  };
};

export const setHidePlayerDownload = (projectId) => {
  return {
    type: "SET_HIDE_PLAYERDOWNLOAD",
    data: { projectid: projectId },
    meta: { remote: true },
  };
};

export const setHideSourceDownload = (projectId) => {
  return {
    type: "SET_HIDE_SOURCEDOWNLOAD",
    data: { projectid: projectId },
    meta: { remote: true },
  };
};

export const setProjectPrivate = (projectId) => {
  return {
    type: "SET_PROJECT_PRIVATE",
    data: { projectid: projectId },
    meta: { remote: true },
  };
};

export const setProjectRestricted = (projectId) => {
  return {
    type: "SET_PROJECT_RESTRICTED",
    data: { projectid: projectId },
    meta: { remote: true },
  };
};

export const setProjectTypeToCourse = (projectId) => {
  return {
    type: "SET_PROJECT_TYPE_AS_COURSE",
    data: { projectid: projectId },
    meta: { remote: true },
  };
};

export const addProjectToCollection = (Id, projectId, collectionid, collectionName, collectionUrl) => {
  return {
    type: "ADD_PROJECT_COLLECTIONS",
    data: {
      projectid: projectId,
      collectionid: collectionid,
      collectionname: collectionName,
      collectionurl: collectionUrl,
      id: Id,
    },
    meta: { remote: true },
  };
};

export const createCollection = (collectionid, collectionName, collectionUrl) => {
  return {
    type: "CREATE_COLLECTION",
    data: {
      collectionid: collectionid,
      collectionname: collectionName,
      collectionurl: collectionUrl,
    },
    meta: { remote: true },
  };
};

export const sellCollection = (collectionid, collectionname) => {
  console.log("sellCollection", collectionid, collectionname);
  return {
    type: "SELL_COLLECTION",
    data: {
      collectionid: collectionid,
      collectionname: collectionname,
    },
    meta: { remote: true },
  };
};

export const addPricing = (pricing) => {
  return {
    type: "CREATE_PRICING",
    data: pricing,
    meta: { remote: true },
  };
};

export const deletePrice = (pricingid) => {
  return {
    type: "DELETE_PRICE",
    data: {
      pricingid: pricingid,
    },
    meta: { remote: true },
  };
};

export const deleteProduct = (collectionid, stripe_product) => {
  return {
    type: "DELETE_PRODUCT",
    data: {
      collectionid: collectionid,
      stripe_productid: stripe_product,
    },
    meta: { remote: true },
  };
};

export const createSeller = () => {
  return {
    type: "CREATE_SELLER",
    data: {},
    meta: { remote: true },
  };
};
export const updateCollectionProductsAndPrices = () => {
  return {
    type: "UPDATE_COLLECTION_PRODUCTSANDPRICES",
    meta: { remote: true },
  };
};

export const deleteProjectFromCollection = (projectId, collectionId) => {
  return {
    type: "DELETE_PROJECT_FROM_COLLECTIONS",
    data: { projectid: projectId, collectionid: collectionId },
    meta: { remote: true },
  };
};

export const checkCollectionName = (collectionurl) => {
  console.log("checking name");
  return {
    type: "CHECK_COLLECTION_NAME",
    data: { collectionurl: collectionurl },
    meta: { remote: true },
  };
};

export const deleteCollection = (collectionId) => {
  return {
    type: "DELETE_COLLECTION",
    data: { collectionid: collectionId },
    meta: { remote: true },
  };
};

export const getCollectionList = () => {
  return {
    type: "GET_COLLECTION_LIST",
    meta: { remote: true },
  };
};

export const getCollectionbyProjectList = () => {
  return {
    type: "GET_COLLECTION_BY_PROJECT_LIST",
    meta: { remote: true },
  };
};

export const getProjectbyCollectionList = () => {
  return {
    type: "GET_PROJECT_BY_COLLECTION_LIST",
    meta: { remote: true },
  };
};

export const getProjects = (page) => {
  return {
    type: "GET_PROJECT_LIST",
    data: { page: page },
    meta: { remote: true },
  };
};

export const getCoursesList = () => {
  return {
    type: "GET_COURSES_LIST",
    meta: { remote: true },
  };
};

export const getFaceList = () => {
  return {
    type: "GET_FACE_LIST",
    meta: { remote: true },
  };
};

export const makefaceSwap = (data) => {
  return {
    type: "FACE_SWAP",
    data,
    meta: { remote: true },
  };
};

export const setPreferredFace = (data) => {
  return {
    type: "SET_PREFERRED_FACE",
    data,
    meta: { remote: true },
  };
};

export const getViewsAndResults = (data) => {
  return {
    type: "GET_VIEWS_AND_RESULTS",
    data,
    meta: { remote: true },
  };
};

export const createSession = (data) => {
  return {
    type: "CREATE_SESSION",
    data,
    meta: { remote: true },
  };
};

export const setProjectName = (data) => {
  return {
    type: "SET_PROJECT_NAME",
    data,
    meta: { remote: true },
  };
};

export const updateSceneDuration = (data) => {
  return {
    type: "EDIT_SCENE_DURATION",
    data,
    meta: { remote: true },
  };
};
export const deleteScene = (data) => {
  return {
    type: "DELETE_SCENE",
    data,
    meta: { remote: true },
  };
};

export const setSceneDescription = (data) => {
  return {
    type: "SET_SCENE_DESCRIPTION",
    data,
    meta: { remote: true },
  };
};

export const setSpeech = (data) => {
  return {
    type: "SET_SPEECH",
    data,
    meta: { remote: true },
  };
};

export const setVirtualPresenter = (data) => {
  return {
    type: "SET_VIRTUAL_PRESENTER",
    data,
    meta: { remote: true },
  };
};

export const expandText = (data) => {
  return {
    type: "EXPAND_TEXT",
    data,
    meta: { remote: true },
  };
};

export const setLabelText = (data) => {
  return {
    type: "SET_LABEL_TEXT",
    data,
    meta: { remote: true },
  };
};

export const sendSupportTicket = (data) => {
  return {
    type: "SEND_SUPPORT_TICKET",
    data,
    meta: { remote: true },
  };
};

export const createLink = (data) => {
  return {
    type: "CREATE_LINK",
    data,
    meta: { remote: true },
  };
};

export const createLinksAndEmails = (data) => {
  return {
    type: "CREATE_LINKS_AND_EMAILS",
    data,
    meta: { remote: true },
  };
};

export const getProjectSessions = (data) => {
  return {
    type: "GET_PROJECT_SESSIONS",
    data,
    meta: { remote: true },
  };
};

export const getAvatarList = () => {
  return {
    type: "GET_AVATAR_LIST",
    meta: { remote: true },
  };
};

export const getSynthesiaVoices = () => {
  return {
    type: "GET_SYNTHESIA_VOICES",
    meta: { remote: true },
  };
};

export const getGoogleVoices = () => {
  return {
    type: "GET_GOOGLE_VOICES",
    meta: { remote: true },
  };
};

export const getAllVoices = () => {
  return {
    type: "GET_ALL_VOICES",
    meta: { remote: true },
  };
};

export const deleteVoice = () => {
  return {
    type: "DELETE_VOICE",
    meta: { remote: true },
  };
};

export const cloneVoice = (data) => {
  return {
    type: "CLONE_VOICE",
    data,
    meta: { remote: true },
  };
};

export const getSessionLinks = (data) => {
  return {
    type: "GET_SESSION_LINKS",
    data,
    meta: { remote: true },
  };
};

export const updateUserInfo = (data) => {
  return {
    type: "UPDATE_USER_INFO",
    data,
    meta: { remote: true },
  };
};

export const userCreateNewApikey = (data) => {
  return {
    type: "USER_CREATE_NEW_APIKEY",
    data,
    meta: { remote: true },
  };
};

export const addToUploadQueue = (data) => {
  return {
    type: "ADD_TO_UPLOAD_QUEUE",
    data,
    meta: { remote: true },
  };
};

export const createStripePortalSession = (data) => {
  return {
    type: "CREATE_STRIPE_PORTAL_SESSION",
    data,
    meta: { remote: true },
  };
};

export const stripePortalSessionClear = () => {
  return {
    type: "STRIPE_PORTAL_SESSION_CLEAR",
  };
};

export const editReady = (data) => {
  return {
    type: "EDIT_READY",
    data,
  };
};

export const loginActual = (data) => {
  return {
    type: "LOGIN",
    data,
    meta: {
      remote: true,
      analytics: true,
      client: "webapp",
      version: "0.7.0",
      theme: process.env.REACT_APP_THEME ? process.env.REACT_APP_THEME : "default",
    },
  };
};

export const getUserCountry = (data) => {
  return {
    type: "GET_USER_COUNTRY",
    data,
    meta: { remote: true },
  };
};

export const analyticsAddToQueue = (data) => {
  return {
    type: "ANALYTICS_ADD_TO_QUEUE",
    data,
    meta: { remote: true },
  };
};

export const registerActual = (data) => {
  return {
    type: "USER_SIGNUP_BY_FORM",
    data,
    meta: {
      remote: true,
      analytics: true,
      client: "webapp",
      version: "0.7.0",
      theme: process.env.REACT_APP_THEME ? process.env.REACT_APP_THEME : "default",
    },
  };
};

export const forgotPassword = (data) => {
  return {
    type: "USER_FORGOT_PASSWORD",
    data,
    meta: {
      remote: true,
      analytics: true,
      client: "webapp",
      version: "0.7.0",
      theme: process.env.REACT_APP_THEME ? process.env.REACT_APP_THEME : "default",
    },
  };
};

export const forgotPasswordFailure = (data) => {
  return {
    type: "USER_FORGOT_PASSWORD_FAILURE",
    data,
  };
};

export const forgotPasswordSuccess = (data) => {
  return {
    type: "USER_FORGOT_PASSWORD_SUCCESS",
    data,
  };
};

export const signUpFailure = (message) => {
  return {
    type: "USER_SIGNUP_FAILURE",
    message,
  };
};

export const loginWithTokenActual = (token) => {
  let data = {
    loginToken: token,
  };
  return {
    type: "LOGIN_WITH_TOKEN",
    data,
    meta: { remote: true },
  };
};

export const loginWithAccessToken = (token) => {
  let data = {
    accessToken: token,
  };
  return {
    type: "LOGIN_WITH_ACCESS_TOKEN",
    data,
    meta: { remote: true },
  };
};

export const loginWithToken = (token) => {
  return (dispatch) => {
    dispatch(loginWithTokenActual(token));
  };
};

export const loginRedirectActual = () => {
  return {
    type: "LOGIN_REDIRECTED",
  };
};
export const loginRedirect = () => {
  return (dispatch) => {
    dispatch(loginRedirectActual());
  };
};

/*
export const loginFailure = message => {
  console.log("login failure " + message);
  return {
    type: "LOGIN_FAILURE",
    message
  };
}; */

export const generateLoginToken = (data) => {
  return {
    type: "GENERATE_LOGIN_TOKEN",
    data,
    meta: { remote: true },
  };
};

export const clearLoginMsgs = () => {
  // console.log("generateLoginToken: " + JSON.stringify(user));
  return {
    type: "CLEAR_LOGIN_MSGS",
  };
};

export const login = (data) => {
  return (dispatch) => {
    dispatch(clearLoginMsgs(data));
    dispatch(loginActual(data));
    //dispatch(generateLoginToken(data));
  };
};

export const register = (data) => {
  return (dispatch) => {
    dispatch(registerActual(data));
    //dispatch(clearLoginMsgs(data))
    //dispatch(loginActual(data))
    //dispatch(generateLoginToken(data))
  };
};

export const loadConfig = () => {
  return { type: "LOAD_CONFIG", data: {}, meta: { remote: true } };
};

export const logout = (data) => {
  return {
    type: "LOGOUT",
    data,
    meta: { remote: true, analytics: true },
  };
};

export const terminateImport = (data) => {
  return {
    type: "TERMINATE_IMPORT",
    data,
    meta: { remote: true },
  };
};
export const updateSynthesiaTermsAndConditions = () => {
  console.log("sending SYNTHESIA_TERMS_CONDITIONS");
  return {
    type: "SYNTHESIA_TERMS_CONDITIONS",
    meta: { remote: true },
  };
};

export const userDownloadedVideo = (data) => {
  return {
    type: "USER_DOWNLOADED_VIDEO",
    data,
    meta: { remote: true, analytics: true },
  };
};

export const userDownloadedGif = (data) => {
  return {
    type: "USER_DOWNLOADED_GIF",
    data,
    meta: { remote: true, analytics: true },
  };
};

export const userDownloadedPlayer = (data) => {
  return {
    type: "USER_DOWNLOADED_PLAYER",
    data,
    meta: { remote: true, analytics: true },
  };
};

export const freeUserClickedDownloadedVideo = (data) => {
  return {
    type: "FREE_USER_CLICKED_DOWNLOAD_VIDEO",
    data,
    meta: { remote: true, analytics: true },
  };
};

export const clear_global_error = () => {
  return {
    type: "CLEAR_GLOBAL_ERROR",
  };
};

export const test_fire_global_error = () => {
  return {
    type: "DOWNLOAD_TO_MEDIALIBRARY_FAILURE",
  };
};

export const get_work_history_actual = (data) => {
  // it takes the following data:
  return {
    type: "GET_WORK_HISTORY",
    data,
    meta: { remote: true },
  };
};

export const get_work_history_start = (data = null) => {
  return {
    type: "START_WORK_HISTORY_GET",
    data,
  };
};

export const get_work_history_end = (data = null) => {
  return {
    type: "END_WORK_HISTORY_GET",
    data,
  };
};

export const get_work_history = (data) => {
  // it takes the following data:
  return (dispatch) => {
    dispatch(get_work_history_start(data));
    dispatch(get_work_history_actual(data));
  };
};

export const loadProjectPre = (data) => {
  return {
    type: "CLEAR_SELECTED_SCENES",
    data,
    // meta: {remote:true}
  };
};
export const loadProjectPost = (data) => {
  return {
    type: "LOAD_PROJECT",
    data,
    meta: { remote: true, analytics: true },
  };
};

export const deleteProject = (data) => {
  return {
    type: "DELETE_PROJECT",
    data,
    meta: { remote: true },
  };
};

export const keepConnectionAlive = (data) => {
  return {
    type: "KEEP_CONNECTION_ALIVE",
    data,
    meta: { remote: true },
  };
};

export const showConvertFullPowerPointButtonToAnalytics = (data) => {
  return {
    type: "SHOW_CONVERT_FULL_POWERPOINT_BUTTON",
    data,
    meta: { analytics: true },
  };
};

export const convertFullPowerPointClickedToAnalytics = (data) => {
  return {
    type: "CONVERT_FULL_POWERPOINT_CLICKED",
    data,
    meta: { analytics: true },
  };
};

export const showPaymentOptionsToAnalytics = (data) => {
  return {
    type: "SHOW_PAYMENT_OPTIONS",
    data,
    meta: { analytics: true },
  };
};

export const PaymentCompletedToAnalytics = (data) => {
  return {
    type: "PAYMENT_COMPLETED",
    data,
    meta: { analytics: true },
  };
};

export const PaymentFailedToAnalytics = (data) => {
  return {
    type: "PAYMENT_FAILED",
    data,
    meta: { analytics: true },
  };
};

export const startPaymentProcessToAnalytics = (data) => {
  return {
    type: "START_PAYMENT_PROCESS",
    data,
    meta: { analytics: true },
  };
};

export const resetVideoData = () => {
  return {
    type: "RESET_VIDEO_DATA",
  };
};

export const hideUpdateVideo = () => {
  return {
    type: "HIDE_UPDATE_VIDEO",
  };
};

export const newProject = (data) => {
  return (dispatch) => {
    if (data != "") {
      dispatch(resetVideoData());

      dispatch(actualNewProject(data));
    }
  };
};

export const actualNewProject = (data) => {
  return {
    type: "NEW_PROJECT_ID",
    data,
  };
};

export const loadProject = (data) => {
  return (dispatch) => {
    dispatch(loadProjectPre(data));
    dispatch(loadProjectPost(data));
  };
};

export const select_scene_id = (data) => {
  return {
    type: "SELECT_SCENE_ID",
    data,
  };
};

export const select_scene_data = (data) => {
  return {
    type: "SELECT_SCENE",
    data,
  };
};

export const renderVideo = (data) => {
  return {
    type: "RENDER_VIDEO",
    data,
    meta: { remote: true },
  };
};

export const pptxPrepareReimport = (data) => {
  return {
    type: "PPTX_PREPARE_REIMPORT",
    data,
    meta: { remote: true },
  };
};

export const getImportEntityState = (data) => {
  return {
    type: "GET_IMPORT_ENTITY_STATE",
    data,
    meta: { remote: true },
  };
};

export const creditsAddPending = (data) => {
  return {
    type: "CREDITS_ADD_PENDING",
    data,
    meta: { remote: true },
  };
};

export const addToCart = (data) => {
  return {
    type: "ADD_TO_CART",
    data,
  };
};

export const emptyCart = () => {
  return {
    type: "EMPTY_CART",
  };
};

export const subscriptionsAddPending = (data) => {
  return {
    type: "SUBSCRIPTIONS_ADD_PENDING",
    data,
    meta: { remote: true },
  };
};

export const subscriptionsUpdate = (data) => {
  return {
    type: "SUBSCRIPTIONS_UPDATE",
    data,
    meta: { remote: true },
  };
};

export const select_scene = (data) => {
  // alert(JSON.stringify(data))
  return (dispatch) => {
    dispatch(select_scene_id(data));
    dispatch(select_scene_data(data));
  };
};

export const clearUploads = (data) => {
  // alert(JSON.stringify(data))
  return {
    type: "CLEAR_UPLOADS",
    data,
  };
};

export const clearPptxUpload = (data) => {
  // alert(JSON.stringify(data))
  return {
    type: "CLEAR_PPTX_UPLOAD",
    data,
  };
};

export const UpdateWindowDimensionsActual = (data = null) => {
  return {
    type: "UPDATE_WINDOW_DIMENSIONS",
    data,
  };
};

export const UpdateWindowDimensions =
  (data = null) =>
  (dispatch) => {
    if (data) {
      let playerRes = {};
      const { width } = data;
      if (width >= 1920 && width < 2560) {
        data.type = "large2";
        playerRes.width = 960;
        playerRes.height = 625;
      } else if (width >= 1540 && width < 1920) {
        data.type = "large1";
        playerRes.width = 708;
        playerRes.height = 418;
      } else if (width >= 1366 && width < 1540) {
        // resolution.width
        data.type = "normal";
        playerRes.width = 689;
        playerRes.height = 450;
      } else if (width > 1024 && width < 1366) {
        data.type = "medium";
        playerRes.width = 640;
        playerRes.height = 427;
      } else if (width > 768 && width <= 1024) {
        data.type = "small";
        playerRes.width = 540;
        playerRes.height = 360;
      }
      data.playerResolution = playerRes;
    }
    console.log("WINDOW DIMENSIONS", data);
    dispatch(UpdateWindowDimensionsActual(data));
  };

export function clearEditPlaceholder(data) {
  return {
    type: "CLEAR_EDIT_PLACEHOLDER",
    data,
  };
}

export function editAvatarPlaceholderPre(data) {
  return {
    type: "EDIT_PLACEHOLDER_AVATAR_PRE",
    data,
    meta: {
      remote: true,
    },
  };
}

export function editAvatarPlaceholderPost(data) {
  return {
    type: "EDIT_PLACEHOLDER_AVATAR",
    data,
    meta: {
      remote: true,
    },
  };
}

export function editAvatarPlaceholder(data) {
  return (dispatch) => {
    dispatch(editAvatarPlaceholderPre(data));
    dispatch(editAvatarPlaceholderPost(data));
  };
}

export const editPlaceholderActual = (data) => {
  return {
    type: "EDIT_PLACEHOLDER",
    data,
    meta: { remote: true },
  };
};
export const editPlaceholderPre = (data, atype) => {
  return {
    type: "EDIT_PLACEHOLDER_PRE",
    action_type: atype,
    data,
    // meta: {remote:true}
  };
};

export const editPlaceholder = (data) => (dispatch) => {
  dispatch(editPlaceholderPre(data));
  dispatch(editPlaceholderActual(data));
};

export const editPlaceholderText = (data) => (dispatch) => {
  dispatch(editPlaceholderPre(data, "text"));
  dispatch(editPlaceholderActual(data));
};

export const DeletePlaceholder = (data) => {
  return {
    type: "DELETE_PLACEHOLDER",
    data,
    meta: { remote: true },
  };
};

export const addPlaceholderPre = (data) => {
  return {
    type: "ADDING_PLACEHOLDER",
    data,
  };
};

export const addPlaceholderPost = (data) => {
  return {
    type: "ADD_PLACEHOLDER",
    data,
    meta: { remote: true },
  };
};

export const addPlaceholder = (data, placeholders) => {
  return (dispatch) => {
    var xdata = {
      addData: data,
      placeholders: placeholders,
    };
    dispatch(addPlaceholderPre(xdata));
    dispatch(addPlaceholderPost(data));
  };
};

export const clearSceneReloadVar = (data = null) => {
  return {
    type: "UPDATE_VIDEO_SCENE_ATTRIBUTES_CLEAR",
    data,
  };
};

export const changingScene = (data = true) => {
  return {
    type: "UPDATE_VIDEO_CHANGING_SCENE",
    data,
  };
};
export const changedScene = (data = false) => {
  return {
    type: "UPDATE_VIDEO_CHANGED_SCENE",
    data,
  };
};

export const reloadScene = (data) => {
  return {
    type: "FRONTEND_TRIGGERED_RELOAD",
    data,
  };
};

export const editorModalStatus = (data) => {
  return {
    type: "EDITOR_MODAL_STATUS",
    data,
  };
};

export const updateMediaLibraryHeightActual = (data) => {
  return {
    type: "UPDATE_MEDIALIBRARY_HEIGHT",
    data,
  };
};

export const updateMediaLibraryHeight = (data) => {
  return (dispatch) => {
    // window.ee.emitEvent('MEDIALIBRARY_HEIGHT_UDPATE', [data]);
    dispatch(updateMediaLibraryHeightActual(data));
    var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    // var header = this.refs;
    var percent = Math.floor((data / h) * 100);
  };
};

export const selectAsset = (data) => {
  return {
    type: "SELECT_ASSET",
    data,
  };
};
